@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {}

body {
  @apply m-0 w-full min-h-screen text-primary leading-7;
}

.primary-button {
  @apply text-neutral-50 bg-primary border-none px-4 py-2 text-center no-underline inline-block cursor-pointer
  disabled:cursor-not-allowed disabled:opacity-50 w-fit;

  app-loading {
    @apply mt-0.5 ml-2 float-right;
  }
}

.default-button {
  @apply text-primary bg-grey border-none px-4 py-2 text-center no-underline inline-block cursor-pointer
  disabled:cursor-not-allowed disabled:opacity-50;

  app-loading {
    @apply mt-0.5 ml-2 float-right;
  }
}

.divider {
  @apply bg-primary mb-4;
  height: 4px;
  width: 70px;
}

input[type=email], input[type=text], input[type=tel], textarea {
  @apply w-full px-5 py-3 mx-0 my-2 inline-block border box-border border-dark-grey;
}

input:active, input:focus-visible, textarea:focus-visible {
  @apply border-primary outline-none;
}

.btn-container {
  @apply mt-8 flex justify-between;
}

div.ngm-modal-close {
  min-height: 25px;
  padding: 0 !important;
}

div.ngm-modal {
  @apply relative;
  max-width: 90% !important;
}

.content-space {
  @apply px-8 sm:px-0 mt-12;
}

ul {
  @apply list-disc m-4;
}

h1 {
  @apply uppercase text-3xl mb-1 font-bold;
}

h2 {
  @apply uppercase text-xl mb-1 font-bold;
}

h3 {
  @apply text-lg mb-1 font-bold text-secondary mt-8;
}

a {
  @apply cursor-pointer no-underline flex items-center;
}

.logo {
  @apply m-0 md:my-10 flex justify-center items-center w-full md:w-fit mx-auto;
}

.blog-content {
  ul {
    margin-left: 3rem !important;
  }
}

.first-post, .white-card {
  a {
    margin-top: 0 !important;
  }
}

.blog-content {
  a {
    @apply cursor-pointer no-underline inline items-center;
  }

  img {
    @apply m-auto;
  }

  p {
    margin-bottom: 1rem;
  }
}
